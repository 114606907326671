.custom-quill {
  border-radius: 0.375rem;
  font-family:
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
}

.custom-quill .ql-toolbar {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  background-color: #f3f4f6;
}

.custom-quill .ql-toolbar .ql-picker-label,
.custom-quill .ql-toolbar button {
  color: #4b5563; /* gray-600 */
}

.custom-quill .ql-toolbar .ql-picker-label:hover,
.custom-quill .ql-toolbar button:hover,
.custom-quill .ql-toolbar .ql-picker-label.ql-active,
.custom-quill .ql-toolbar button.ql-active {
  color: #8b5cf6; /* purple-500 */
}

.custom-quill .ql-toolbar .ql-picker-label .ql-stroke,
.custom-quill .ql-toolbar button .ql-stroke {
  stroke: #4b5563; /* gray-600 */
}

.custom-quill .ql-toolbar .ql-picker-label .ql-fill,
.custom-quill .ql-toolbar button .ql-fill {
  fill: #4b5563; /* gray-600 */
}

.custom-quill .ql-toolbar .ql-picker-label:hover .ql-stroke,
.custom-quill .ql-toolbar button:hover .ql-stroke,
.custom-quill .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.custom-quill .ql-toolbar button.ql-active .ql-stroke {
  stroke: #8b5cf6; /* purple-500 */
}

.custom-quill .ql-toolbar .ql-picker-label:hover .ql-fill,
.custom-quill .ql-toolbar button:hover .ql-fill,
.custom-quill .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.custom-quill .ql-toolbar button.ql-active .ql-fill {
  fill: #8b5cf6; /* purple-500 */
}

.custom-quill .ql-container {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  background-color: #ffffff;
}

.custom-quill:focus-visible {
  outline: 2px solid #8b5cf6; /* purple-500 */
  outline-offset: 2px;
}

.custom-quill:focus-within .ql-toolbar,
.custom-quill:focus-within .ql-container {
  border: 1px solid #8b5cf6; /* purple-500 */
}

.custom-quill .ql-editor {
  min-height: 100px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.01em;
  /* color: #6b7280; Equivalent to text-gray-500 */
}

.custom-quill .ql-editor a {
  color: #8b5cf6; /* purple-500 */
}

.custom-quill .ql-editor.ql-blank::before {
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.01em;
  color: #6b7280; /* Matching the text color */
}

/* Hyperlink popout styles */
.custom-quill .ql-snow .ql-tooltip {
  border-color: #8b5cf6; /* purple-500 */
  background-color: #ffffff;
}

.custom-quill .ql-snow .ql-tooltip input[type="text"] {
  border-color: #8b5cf6; /* purple-500 */
}

.custom-quill .ql-snow .ql-tooltip a.ql-action,
.custom-quill .ql-snow .ql-tooltip a.ql-remove,
.custom-quill .ql-snow .ql-tooltip a {
  color: #8b5cf6; /* purple-500 */
}
